<template>
  <div>
    <nav class="navbar" :class="{ 'sticky': isSticky }">
      <div class="">
        <v-row align="center"> <!-- Center content vertically -->
          <v-col cols="12" sm="6" md="8"> <!-- Logo column -->
              <div class="d-flex justify-between align-center"> <!-- Apply flex properties -->
                  <!-- Logo -->
                    <router-link to="/" class="logo" >
                      <v-img src="@/assets/logo.png" alt="Logo"></v-img>
                  </router-link>                  
                  <!-- Menu icon -->
                  <v-btn 
                      class="d-sm-none d-flex menu-icon"
                      id="menu-activator"
                  >
                      <v-icon style="font-size: 24px;">mdi-menu</v-icon>
                  </v-btn>
                  <!-- Menu -->
                  <v-menu activator="#menu-activator">
                      <v-list>
                          <v-list-item 
                              v-for="(navBarItem, index) in navBarItems" 
                              :key="index" 
                              @click="$router.push(navBarItem.path)"
                              :value="index"
                          >
                              <v-list-item-title class="custom-list-item">{{ navBarItem.title }}</v-list-item-title>
                          </v-list-item>
                      </v-list>
                  </v-menu>
              </div>
          </v-col>
          
          <v-col cols="12" sm="6" md="4"> <!-- Second column for navBarItems -->
              <div class="navbar-items d-flex flex-row justify-end"> <!-- Apply flex properties -->
                  <router-link 
                      class="d-none d-sm-flex"
                      v-for="(navBarItem, index) in navBarItems" 
                      :key="index" 
                      :to="navBarItem.path"
                      :class="{ 'active': $route.path === navBarItem.path }"
                  >
                      {{ navBarItem.title }}
                  </router-link>
              </div>
          </v-col>
      </v-row>
      
      
      
      
      
 


       
      
    
    
      </div>
    </nav>

  </div>
</template>

<script>
export default {
  name: 'StickyNavbar',
  data() {
    return {
      isSticky: false,
      navBarItems: [
        { title: 'Home', path: '/' },
        { title: 'About', path: '/about' },
        // { title: 'Charging Stations', path: '/charging-stations' },
        { title: 'No Cost Model', path: '/no-cost-model' },
        { title: 'Contact Us', path: '/contact-us' },
      ],
      // Adjust the offset if needed, e.g., for a taller navbar
      offset: 0,
      drawer: false
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    showMenuIcon() {
      // Add your logic to determine when to show the menu icon
      // For example, show it when the screen width is less than 600px
      return window.innerWidth < 450;
    }
  },
  methods: {
    handleScroll() {
      this.isSticky = window.pageYOffset > this.offset;
    },
    toggleDrawer() {
      this.drawer = !this.drawer;
    }
  }
};
</script>

<style scoped>
/* Your existing styles */
.navbar {
  background-color: black;
  color: #fff;
  padding: 15px 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000; /* Ensure navbar remains on top */
}

.active {
  color: green; /* Change font color to green for active page */
}
.navbar.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.container {
  display: flex;
  align-items: center;
}



.navbar a {
  text-decoration: none;
  color: white;
  margin: 0 10px;
}

.menu-icon {
  margin-left: auto; /* Push the icon to the right */
  background-color: black;

}

@media screen and (max-width: 360px) {
  .custom-list-item {
    font-size: 12px; /* Adjust font size as needed */
    margin-left: 16px; /* Adjust spacing as needed */
  }

  .logo  {
    width: 120px; /* Adjust logo size as needed */
    margin-left: 40px;
  }
  .navbar {
    background-color: black;
    color: #fff;
    padding-top: 8px;
    padding-bottom: 0px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000; /* Ensure navbar remains on top */
  }
  
}

/* Media query for XS - Small to large phone screens - 361px > < 599px */
@media screen and (min-width: 361px) and (max-width: 599px) {
  .menu-icon {
    margin-left: auto; /* Push the icon to the right */
    background-color: black;

  }
  .logo  {
    width: 120px; /* Adjust logo size as needed */
    margin-left: 20px;
  }
  .custom-list-item {
    font-size: 12px; /* Adjust font size as needed */
    margin-left: 16px; /* Adjust spacing as needed */
  }
  .navbar {
    background-color: black;
    color: #fff;
    padding-top: 8px;
    padding-bottom: 0px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000; /* Ensure navbar remains on top */
  }
  
}
/* Media query for SM - Small to medium tablet screens - 600px > < 960px */
@media screen and (min-width: 600px) and (max-width: 959px) {
  .navbar-items {
    font-size: 8px;
  }
  .logo  {
    width: 90px; /* Adjust logo size as needed */
    margin-left: 20px;
  }
 
}

/* Media query for MD - Large tablet to laptop screens - 960px > < 1280px */
@media screen and (min-width: 960px) and (max-width: 1279px) {
  .navbar-items {
    font-size: 11px;
  }
  .logo  {
    width: 120px; /* Adjust logo size as needed */
    margin-left: 40px;
  }
  .navbar {
    background-color: black;
    color: #fff;
    padding-top: 15px;
    padding-bottom: 10px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000; /* Ensure navbar remains on top */
  }
 

}
/* Media query for LG - Laptop to desktop screens - 1280px > < 1920px */
@media screen and (min-width: 1280px) and (max-width: 1919px) {
  .navbar-items {
    font-size: 12px;

  }
  .logo  {
    width: 150px; /* Adjust logo size as needed */
    margin-left: 40px;
  }

}
/* Media query for XL - 1080p to 1440p desktop - 1920px > < 2560px */
@media screen and (min-width: 1920px) and (max-width: 2560px) {
  .navbar-items {
    font-size: 14px;
    margin-left: 150px;

  }
  .logo  {
    width: 150px; /* Adjust logo size as needed */
    margin-left: 100px;
  }
 
}
/* Media query for XXL - 4k and ultra-wide - > 2560px */
@media screen and (min-width: 2560px) and (max-width: 5430px) {
  .navbarLogo {
    width: 150px;
    position: relative;
    left: 75%;
    /* Optionally, you can add other styles to the button */
  }
  .navbarButtons {
    margin: auto;
  }
  .navbar-items {
    font-size: 12px;
  }
  .logo  {
    width: 150px; /* Adjust logo size as needed */
    margin-left: 100px;
  }
 

}

</style>
