<template>
  <v-app>
    <NavBar />
    <v-main class="main-content" :class="{ 'fade-in': showContent }">
      <Hero />
      <SectionTwo />
      <SectionThree />
      <SectionFour />
      <Footer />
    </v-main>
</v-app>
</template>

<script>

import { defineComponent } from 'vue';

// Components
import NavBar from '../components/Main-NavBar.vue';
import Hero from '../components/About-Page/About-Hero.vue';
import SectionOne from '../components/About-Page/About-SectionOne.vue';
import SectionTwo from '../components/About-Page/About-SectionTwo.vue';
import SectionThree from '../components/About-Page/About-SectionThree.vue';
import SectionFour from '../components/About-Page/About-SectionFour.vue';
import Footer from '../components/Main-Footer.vue';


export default defineComponent({
    name: 'AboutView',
  
    components: {
      NavBar, Hero,SectionOne, SectionTwo, SectionThree, SectionFour, Footer,
    },
    data() {
    return {
      showContent: false,
    };
  },
  mounted() {
    // Set showContent to true after a short delay
    setTimeout(() => {
      this.showContent = true;
    }, 300);
  },
});
</script>
<style>
.main-content {
  max-width: 100%; /* Ensure content does not exceed viewport width */
  overflow-x: hidden; /* Hide horizontal overflow */
}
.fade-in {
  opacity: 100;
  transition: opacity 1.5s ease-in;
}

.fade-in.showContent {
  opacity: 1;

}
</style>

