<template>
        <v-row justify="center" >
          <v-col cols="12" class="d-flex">
            <v-img
            class="image-container"
            aspect-ratio="16/9"
            cover
            src="@/assets/about-image.jpeg"
          >
          </v-img>
        </v-col>

        </v-row>
</template>

<style scoped>
.position-relative {
  position: relative;
}

/* Media query for XS - Small to large phone screens - < 360px */
@media screen and (max-width: 360px) {
  h1 {
    font-size: 16px; /* Adjust font size for h1 */
  }
  .image-container {
    position: relative;
    height: 490px;
    width: 400px;
  }
  .text-overlay {
    position: relative;
    top: 80%;
    left: 60%;
    transform: translate(-50%, -50%);
  }
  .v-btn {
    font-size: 10px; /* Adjust font size for h1 */
  }
  .position-relative {
    position: relative;
  }
}
/* Media query for XS - Small to large phone screens - 361px > < 599px */
@media screen and (min-width: 361px) and (max-width: 599px) {
  h1 {
      font-size: 16px; /* Adjust font size for h1 */
  }
  .image-container {
    position: relative;
    height: 630px !important; /* Adjusted height for smaller screens */
    width: 600px !important; /* Adjusted width for smaller screens */
    object-fit: cover; /* Apply cover only when the cover attribute is true */
  }
  .text-overlay {
    position: relative;
    top: 80%;
    left: 55%;
    transform: translate(-50%, -50%);
  }
  .v-btn {
    font-size: 12px; /* Adjust font size for h1 */
  }
  .position-relative {
    position: relative;
  }

}
/* Media query for SM - Small to medium tablet screens - 600px > < 960px */
@media screen and (min-width: 600px) and (max-width: 960px) {
  .image-container {
    margin: auto;
    position: relative;
    height: 374px;
    border-radius: 0px; /* Set border radius to make it rounded */

  }
 
}

/* Media query for MD - Large tablet to laptop screens - 960px > < 1280px */
@media screen and (min-width: 961px) and (max-width: 1279px) {
  .image-container {
    margin: auto;
    position: relative;
    height: 469px;
    border-radius: 5px; /* Set border radius to make it rounded */
  }

}
/* Media query for LG - Laptop to desktop screens - 1280px > < 1920px */
@media screen and (min-width: 1280px) and (max-width: 1919px) {
  .image-container {
    position: relative;
    height: 500px;
    border-radius: 0px; /* Set border radius to make it rounded */

  }
  .position-relative {
    position: relative;
  }

}
/* Media query for XL - 1080p to 1440p desktop - 1920px > < 2560px */
@media screen and (min-width: 1920px) and (max-width: 2560px) {
  .image-container {
    margin: auto;
    position: relative;
    height: 500px;
    border-radius: 5px; /* Set border radius to make it rounded */
  }

}
/* Media query for XXL - 4k and ultra-wide - > 2560px */
@media screen and (min-width: 2560px) and (max-width: 5430px) {
  h1 {
    font-size: 25px; /* Adjust font size for h1 */
  }
  .image-container {
    margin: auto;
    margin-top: 30px;
    position: relative;
    height: 933px;
    width: 1948px;
    border-radius: 20px; /* Set border radius to make it rounded */
  }
  .text-overlay {
    position: absolute;
    top: 50%;
    left: 15%;
    transform: translate(-50%, -50%);
  }
  
}
</style>
