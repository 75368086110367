import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';
import TailWind from './plugins/index.css';
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VueGtag from 'vue-gtag';

library.add(faPhone);

loadFonts();

const app = createApp(App);
app.use(router);
app.use(vuetify);
app.use(TailWind);
app.use("font-awesome-icon", FontAwesomeIcon);

// Configure Google Analytics
app.use(VueGtag, {
  config: { id: 'G-5X3S213W1V' }
});

// Mount the app
app.mount('#app');
