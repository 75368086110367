<template>
    <section class="green-background pt-8">
        <v-col cols="12">
            <v-row>
                <v-col cols="12">
                        <div align="center">
                            <v-img 
                            aspect-ratio="16/9"
                            height="30px"
                            width="275px"
                            src="@/assets/logo.png"
                            ></v-img>
                        </div>
                </v-col>
            </v-row>
            <v-row justify="center" class="pb-10">
                    <v-col cols="1">
                        <div align="end">
                            <v-avatar class="outlined" size="30">
                                <v-img src="@/assets/facebook.png"></v-img>
                            </v-avatar> 
                        </div>
                    </v-col>
                    <v-col cols="1">
                        <div align="center">
                            <v-avatar class="outlined pa-1" size="30">
                                <v-img src="@/assets/instagram.png"></v-img>
                            </v-avatar> 
                        </div>
                    </v-col>
                    <v-col cols="1">
                        <div align="start">
                            <v-avatar class="outlined" size="30">
                                <v-img src="@/assets/twitter.png"></v-img>
                            </v-avatar> 
                        </div>
                    </v-col>
            </v-row>
            <v-row justify="center" class="pb-5">
                <p>Copyright © 2024 EV Networks Corporation. All rights reserved.</p>

            </v-row>
        </v-col>
    </section>

</template>

<style scoped>
.green-background {
    background: rgb(22, 162, 73);
}
.v-avatar.outlined {
    border: 2px solid currentColor;
    border-radius: 100%;
    height: 56px;
    width: 56px;
    color: black;
    padding: 3px;
    cursor: pointer;

}
p {
    font-family: "Saira", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    font-variation-settings:
        "wdth" 100;
    color: black;
}
</style>
<script>

</script>