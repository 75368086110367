<template>
  <v-container class="py-5">
    <v-row justify="center">
      <v-col cols="12" sm="6">
        <v-row justify="center"> 
          <v-col cols="1" align-self="center">
              <div :class="rectangleStyle(0)"></div>
          </v-col>
          <v-col cols="10" xs="11" sm="10"  align-self="center">
              <v-expansion-panels >
                <v-expansion-panel>
               <v-expansion-panel-title @click="handlePanelClick(0)" class="heading">Empowering EV Journeys</v-expansion-panel-title>
                <v-expansion-panel-text class="p-sub-text">Explore our cutting-edge EV charging solutions, providing high-speed charging and smart technologies to redefine the charging experience.</v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="1">
              <div :class="rectangleStyle(1)"></div>
          </v-col>
          <v-col cols="10" xs="11" sm="10"    align-self="center">
              <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-title @click="handlePanelClick(1)" class="heading">Building Tomorrow's Charging</v-expansion-panel-title>
                    <v-expansion-panel-text class="p-sub-text">Join us in expanding EV charging networks, deploying stations in urban areas and along highways, shaping a future where reliable charging is accessible to all.</v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="6">
        <v-row justify="center">
          <v-col cols="1">
              <div :class="rectangleStyle(2)"></div>
          </v-col>
          <v-col cols="10" xs="11" sm="10"  align-self="center">
              <v-expansion-panels>
                  <v-expansion-panel>
                  <v-expansion-panel-title @click="handlePanelClick(2)" class="heading">Sustainable, Intelligent Charging</v-expansion-panel-title>
                  <v-expansion-panel-text class="p-sub-text">Discover how our installations seamlessly integrate with smart grids, optimizing energy consumption for a sustainable and efficient charging network.</v-expansion-panel-text>
                </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="1">
              <div :class="rectangleStyle(3)"></div>
          </v-col>
          <v-col cols="10" xs="11" sm="10"   align-self="center">
              <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-title @click="handlePanelClick(3)" class="heading">Your Journey, Our Priority</v-expansion-panel-title>
                    <v-expansion-panel-text class="p-sub-text">From hassle-free installations to personalized solutions, we prioritize your EV journey. Join us in pioneering a future where convenience meets sustainable mobility.</v-expansion-panel-text>
                  </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div class="pa-16">
      <v-divider :length="803" :thickness="5" class="border-opacity-0 green"></v-divider>
    </div>

  </v-container>
</template>

<script>
export default {
data() {
  return {
    panelStates: ['first', 'first', 'first', 'first'], // Initial states for each panel
  };
},
computed: {
  rectangleStyle() {
    return (index) =>
      this.panelStates[index] === 'first'
        ? 'rounded-rectangle-first'
        : 'rounded-rectangle-second';
  },
},
methods: {
  handlePanelClick(index) {
    if (this.panelStates[index] === 'first') {
      console.log(`Panel ${index + 1}: First click message`);
      this.panelStates[index] = 'second';
    } else {
      console.log(`Panel ${index + 1}: Second click message`);
      this.panelStates[index] = 'first';
    }
  },
},
};
</script scoped>

<style>
.rounded-rectangle-first {
height: 80px;
width: 13px;
background-color: rgb(22, 162, 73);
border-radius: 3px; /* Adjust the radius as needed */
}

.rounded-rectangle-second {
height: 141px;
width: 13px;
background-color: rgb(22, 162, 73);
border-radius: 3px; /* Adjust the radius as needed */
}
.heading {
font-family: "Saira", sans-serif;
font-optical-sizing: auto;
font-weight: 600;
font-style: normal;
font-variation-settings:
  "wdth" 100;
color: black;
}
.p-sub-text {
font-family: "Roboto Serif", serif;
font-optical-sizing: auto;
font-weight: 500;
font-style: normal;
font-variation-settings:
    "wdth" 100;
color: black;
}
.green {
    color: rgb(22, 162, 73);
}
/* Media query for XS - Small to large phone screens - < 360px */
@media screen and (max-width: 360px) {
  .heading {
      font-size: 10px;
    }
    .p-sub-text {
      font-size: 10px;
    }
    .rounded-rectangle-first {
      height: 50px;
      width: 13px;
      background-color: rgb(22, 162, 73);
      border-radius: 3px; /* Adjust the radius as needed */
    }

    .rounded-rectangle-second {
      height: 145px;
      width: 13px;
      background-color: rgb(22, 162, 73);
      border-radius: 3px; /* Adjust the radius as needed */
    }
   
}
/* Media query for XS - Small to large phone screens - 361px > < 599px */
@media screen and (min-width: 361px) and (max-width: 599px) {
  .heading {
      font-size: 14px;
    }
    .p-sub-text {
      font-size: 12px;
    }
    .rounded-rectangle-first {
      height: 60px;
      width: 13px;
      background-color: rgb(22, 162, 73);
      border-radius: 3px; /* Adjust the radius as needed */
    }

    .rounded-rectangle-second {
      height: 185px;
      width: 13px;
      background-color: rgb(22, 162, 73);
      border-radius: 3px; /* Adjust the radius as needed */
    }
}
/* Media query for SM - Small to medium tablet screens - 600px > < 960px */
@media screen and (min-width: 600px) and (max-width: 960px) {
  .heading {
      font-size: 14px;
    }
    .p-sub-text {
      font-size: 11px;
    }
    .rounded-rectangle-first {
      height: 50px;
      width: 13px;
      background-color: rgb(22, 162, 73);
      border-radius: 3px; /* Adjust the radius as needed */
    }

    .rounded-rectangle-second {
      height: 145px;
      width: 13px;
      background-color: rgb(22, 162, 73);
      border-radius: 3px; /* Adjust the radius as needed */
    }
   
}

/* Media query for MD - Large tablet to laptop screens - 960px > < 1280px */
@media screen and (min-width: 960px) and (max-width: 1280px) {
    h1 {
        font-size: 18px; /* Adjust the font size for smaller screens */
    }
    p {
        font-size: 12px; /* Adjust font size for p */
    }
    .image-container {
      position: relative;
      width: 450px;
      padding-top: 10px;
      justify-content: center; /* Center content horizontally */
      align-items: center; /* Center content vertically */
    }
    .rounded-picture-border {
      position: absolute;
      top: -5%;
      right: -40px;
      transform: translateY(15%);
      height: 100%;
      width: 457px;
      border: 3px solid rgb(22, 162, 73);
      border-radius: 10px;
      z-index: 1;
    }
    .box-container {
      padding-top: 130px;
    }
    .rounded-rectangle {
      height: 174px;
      width: 35px;
      background-color: rgb(22, 162, 73);
      border-radius: 5px; /* Adjust the radius as needed */
    }

}
/* Media query for LG - Laptop to desktop screens - 1280px > < 1920px */
@media screen and (min-width: 1280px) and (max-width: 1920px) {
    h1 {
        font-size: 20px; /* Adjust the font size for smaller screens */
      }
    p {
        font-size: 12px; /* Adjust font size for p */

    }
    .image-container {
      position: relative;
      width: 450px;
    }
    .rounded-picture-border {
      position: absolute;
      top: -5%;
      right: -40px;
      transform: translateY(15%);
      height: 100%;
      width: 450px;
      border: 3px solid rgb(22, 162, 73);
      border-radius: 10px;
      z-index: 1;
    }
    .box-container {
      padding-left: 100px;
    }
    .rounded-rectangle {
      height: 254px;
      width: 35px;
      background-color: rgb(22, 162, 73);
      border-radius: 5px; /* Adjust the radius as needed */
    }

}
/* Media query for XL - 1080p to 1440p desktop - 1920px > < 2560px */
@media screen and (min-width: 1920px) and (max-width: 2560px) {
    h1 {
        font-size: 18px; /* Adjust font size for h1 */
        margin-bottom: 10px; /* Adjust margin bottom for h1 */
    }
    p {
      font-size: 12px; /* Adjust font size for p */
      padding-right: 50px;
    }
    .image-container {
      margin: auto;
      position: relative;
      width: 450px;
      padding-top: 30px;
    }
    .rounded-picture-border {
      position: absolute;
      top: 10%;
      right: -25px;
      transform: translateY(15%);
      height: 85%;
      width: 450px;
      border: 3px solid rgb(22, 162, 73);
      border-radius: 10px;
      z-index: 1;
    }
    .text-container {
      margin: auto;
    }
    .rounded-rectangle {
      height: 254px;
      width: 35px;
      background-color: rgb(22, 162, 73);
      border-radius: 5px; /* Adjust the radius as needed */
    }
    .box-container {
      padding-right: 100px;
    }
    

}
/* Media query for XXL - 4k and ultra-wide - > 2560px */
@media screen and (min-width: 2560px) and (max-width: 5430px) {
    h1 {
        font-size: 25px; /* Adjust font size for h1 */
        margin-bottom: 10px; /* Adjust margin bottom for h1 */
    }
    p {
      font-size: 12px; /* Adjust font size for p */
      padding-right: 50px;
    }
    .image-container {
      margin: auto;
      position: relative;
      width: 450px;
      padding-top: 30px;
    }
    .rounded-picture-border {
      position: absolute;
      top: 10%;
      right: -25px;
      transform: translateY(15%);
      height: 85%;
      width: 450px;
      border: 3px solid rgb(22, 162, 73);
      border-radius: 10px;
      z-index: 1;
    }
   
    .text-container {
      margin: auto;
    }
    .rounded-rectangle {
      height: 254px;
      width: 35px;
      background-color: rgb(22, 162, 73);
      border-radius: 5px; /* Adjust the radius as needed */
    }
    
}
</style>
