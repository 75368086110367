<template>
  <v-container> 
    <v-row justify="center">
      <v-col cols="12" sm="10" md="12" xl="12">
        <v-row justify="center" class="pb-4 pt-4 px-2">
          <v-img
            class="position-relative image-container"
            aspect-ratio="16/9"
            cover
            src="@/assets/banner-image.jpeg"
          >
            <div class="text-overlay">
              <h1 class="text-uppercase">Convenient charging</h1>
              <h1 class="text-uppercase">for your EV vehicle!</h1>
            </div>
          </v-img>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

</script>

<style scoped>

.text-overlay {
  position: absolute;
  top: 50%;
  left: 20%;
  transform: translate(-50%, -50%);
  text-align: center;
}

h1 {
  font-family: "Saira", sans-serif;
  font-weight: 500;
  font-style: normal;
  color: white;
}

/* Media query for XS - Small to large phone screens - < 360px */
@media screen and (max-width: 360px) {
  h1 {
    font-size: 20px; /* Adjust font size for h1 */
    font-weight: 800;
    background-color: rgba(0, 0, 0, 0.493);
  }
  .image-container {
    margin-left: 20px; /* Adjust left margin */
    margin-right: 20px; /* Adjust left margin */
    position: relative;
    height: 669px;
    width: 1636px;
  }
  .text-overlay {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .v-btn {
    left: 25%;
  }
}
/* Media query for XS - Small to large phone screens - 361px > < 599px */
@media screen and (min-width: 361px) and (max-width: 599px) {
  h1 {
    font-size: 20px; /* Adjust font size for h1 */
    font-weight: 800;
    background-color: rgba(0, 0, 0, 0.493);
  }
  .image-container {
    position: relative;
    height: 469px;
    width: 1636px;
  }
  .text-overlay {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
}
/* Media query for SM - Small to medium tablet screens - 600px > < 960px */
@media screen and (min-width: 600px) and (max-width: 959px) {
  h1 {
    font-size: 25px; /* Adjust font size for h1 */
  }
  .image-container {
    margin-left: 20px; /* Adjust left margin */
    margin-right: 20px; /* Adjust left margin */
    position: relative;
    height: 369px;
    width: 536px;
    border-radius: 5px;
  }
  .text-overlay {
    position: relative;
    top: 50%;
    left: 30%;
    transform: translate(-50%, -50%);
  }

}

/* Media query for MD - Large tablet to laptop screens - 960px > < 1280px */
@media screen and (min-width: 960px) and (max-width: 1280px) {
  h1 {
    font-size: 25px; /* Adjust font size for h1 */
  }
  .image-container {
    margin-left: 20px; /* Adjust left margin */
    margin-right: 20px; /* Adjust left margin */
    position: relative;
    height: 300px;
    width: 116px;
  }
  .text-overlay {
    position: relative;
    top: 60%;
    left: 25%;
    transform: translate(-50%, -50%);
  }

}
/* Media query for LG - Laptop to desktop screens - 1280px > < 1920px */
@media screen and (min-width: 1280px) and (max-width: 1919px) {
  h1 {
    font-size: 30px; /* Adjust font size for h1 */
  }
  .text-overlay {
    position: absolute;
    top: 50%;
    left: 20%;
    transform: translate(-50%, -50%);
  }
  .image-container {
    margin-top: 30px;
    position: relative;
    height: 550px;
    width: 906px;
    border-radius: 5px; /* Set border radius to make it rounded */
    margin-left: 50px;
    margin-right: 50px;
  }

}
/* Media query for XL - 1080p to 1440p desktop - 1920px > < 2560px */
@media screen and (min-width: 1920px) and (max-width: 2559px) {
  h1 {
    font-size: 25px; /* Adjust font size for h1 */
  }
  .text-overlay {
    position: absolute;
    top: 50%;
    left: 20%;
    transform: translate(-50%, -50%);
  }
  .image-container {
    margin-top: 30px;
    position: relative;
    height: 450px;
    width: 706px;
    border-radius: 5px; /* Set border radius to make it rounded */
    margin-left: 150px;
    margin-right: 150px;
  }
}
/* Media query for XXL - 4k and ultra-wide - > 2560px */
@media screen and (min-width: 2560px) and (max-width: 5430px) {
  h1 {
    font-size: 25px; /* Adjust font size for h1 */
    margin-top: 5px; /* Adjust top margin */
    margin-left: 100px; /* Adjust left margin */
  }
  .text-overlay {
    position: absolute;
    top: 50%;
    left: 20%;
    transform: translate(-50%, -50%);
  }
  .image-container {
    margin-top: 30px;
    position: relative;
    height: 550px;
    width: 906px;
    border-radius: 5px; /* Set border radius to make it rounded */
    margin-left: 150px;
    margin-right: 150px;
  }
  
}
</style>
