<template>
<v-container class="px-16 py-16">
    <v-row justify="start">
        <v-col class="" cols="12" xs="12" sm="7" lg="6" xl="6" offset-lg="1" align-self="center">
            <v-row justify="start" class="p-container">
                <v-col cols="1" align-self="center" class="">
                    <v-row justify="end">
                        <div  class="rounded-rectangle"></div>
                     </v-row>
                </v-col>
                <v-col  align-self="center" cols="10" xs="12" sm="11" md="11" lg="11">
                    <h1>Our Mission</h1>
                    <v-divider
                    length="450px"
                    :thickness="5"
                    class="py-2 border-opacity-100 green"
                ></v-divider>
                <p>
                    Our mission is to provide unparalleled convenience to EV users, 
                    promoting cleaner transportation and integrating EVs seamlessly
                    into their daily lives.
                </p>
                </v-col>
        </v-row>

            <v-row class="green-container">
                        <v-col cols="6" sm="4" xl="4" offset-sm="2">
                            <div class="container rounded-xl">
                                <v-img class="image" src="@/assets/battery-charging-50.png" alt="Your Image"></v-img>
                                <div class="overlay">
                                  <div class="overlay-content">
                                    <!-- Your content goes here -->
                                  </div>
                                </div>
                              </div>
                            </v-col>   
                        <v-col cols="6" sm="4" xl="5" offset-sm="1" >
                            <div class="container rounded-xl pt-16">
                                <v-img class="image" src="@/assets/car-connected.png" alt="Your Image"></v-img>
                                <div class="overlay">
                                  <div class="overlay-content">
                                    <!-- Your content goes here -->
                                  </div>
                                </div>
                              </div>
                        </v-col>
                        <v-col cols="6" sm="4" xl="4"  offset-sm="2">
                            <div class="container rounded-xl">
                                <v-img class="image" src="@/assets/domain.png" alt="Your Image"></v-img>
                                <div class="overlay">
                                  <div class="overlay-content">
                                    <!-- Your content goes here -->
                                  </div>
                                </div>
                              </div>
                        </v-col>
                        <v-col cols="6" sm="4" xl="5" offset-sm="1">
                            <div class="container rounded-xl pt-16">
                                <v-img class="image" src="@/assets/lightning-bolt.png" alt="Your Image"></v-img>
                                <div class="overlay">
                                  <div class="overlay-content">
                                    <!-- Your content goes here -->
                                  </div>
                                </div>
                              </div>
                        </v-col>
            </v-row>
        </v-col>
        
        <v-col cols="12" sm="4" md="2" xl="4">
            <div align="center" class="image-container">
                <v-img
                class="rounded-xl image-section"
                aspect-ratio="1/1"
                cover
                src="@/assets/about-image-2.jpeg"
              ></v-img>  
              <div class="rounded-picture-border"></div>   
            </div>
        </v-col>
    </v-row>
</v-container>
</template>

<style scoped>
h1 {
    font-family: "Saira", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-variation-settings:
      "wdth" 100;
    color: black;
}
p {
    font-family: "Roboto Serif", serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
    font-variation-settings:
        "wdth" 100;
    color: black;
}
.p-sub-text {
    font-family: "Roboto Serif", serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
    font-size: 18px;
    font-variation-settings:
        "wdth" 100;
    color: black;
}
.green {
    color: rgb(22, 162, 73);
}
.rounded-rectangle {
    height: 154px;
    width: 35px;
    background-color: rgb(22, 162, 73);
    border-radius: 5px; /* Adjust the radius as needed */
}
.container {
    position: relative;
    width: 182px;
    height: 112px;
    overflow: hidden;
    border-radius: 20px;
    box-shadow: 20px 10px 6px rgba(0, 0, 0, 0.5);
}
.image {
    width: 40%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
    z-index: 1;
    border-radius: 30px;
}
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(22, 162, 73);
    z-index: 0;
    border-radius: 20px;
}

.overlay-content {
    text-align: center;
    color: #fff;
}
.rounded-rectangle {
    height: 254px;
    width: 35px;
    background-color: rgb(22, 162, 73);
    border-radius: 5px; /* Adjust the radius as needed */
}
.image-container {
    position: relative;
}

.rounded-picture-border {
    position: absolute;
    top: -8%;
    right: -1px;
    transform: translateY(15%);
    height: 100%;
    width: 432px;
    border: 3px solid rgb(22, 162, 73);
    border-radius: 30px;
    z-index: 1;
}
.image-section {
    z-index: 2; /* Adjust the z-index of the image */
}
/* Media query for XS - Small to large phone screens - < 360px */
@media screen and (max-width: 360px) {
    h1 {
        font-size: 16px; /* Adjust font size for h1 */
    }
    p {
        font-size: 12px; /* Adjust font size for p */
        padding-bottom: 10px;
    }
    .rounded-rectangle {
      height: 205px;
      width: 25px;
      background-color: rgb(22, 162, 73);
      border-radius: 5px; /* Adjust the radius as needed */
    }
    .container {
      position: relative;
      width: 100px;
      height: 90px;
      overflow: hidden;
      border-radius: 20px;
      box-shadow: 20px 10px 6px rgba(0, 0, 0, 0.5);
  }
  .image {
      width: 40%;
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      object-fit: cover;
      z-index: 1;
      border-radius: 30px;
  }
  .rounded-picture-border {
      position: absolute;
      top: 5%;
      right: 5px;
      transform: translateY(15%);
      height: 85%;
      width: 200px;
      border: 3px solid rgb(22, 162, 73);
      border-radius: 30px;
      z-index: 1;
  }
  .image-section {
      z-index: 2; /* Adjust the z-index of the image */
      top: 50%;
      left: -10%;
      margin-top: 10%;
  }
  .image-container {
      margin: auto;
      position: relative;
      width: 200px;
      padding-top: 30px;
      right: 5px;
    }
     
  }
  /* Media query for XS - Small to large phone screens - 361px > < 599px */
  @media screen and (min-width: 361px) and (max-width: 599px) {
      h1 {
          font-size: 16px; /* Adjust font size for h1 */
      }
      p {
          font-size: 12px; /* Adjust font size for p */
          padding-bottom: 10px;
      }
      .rounded-rectangle {
        height: 155px;
        width: 25px;
        background-color: rgb(22, 162, 73);
        border-radius: 5px; /* Adjust the radius as needed */
      }
    .image {
        width: 40%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        object-fit: cover;
        z-index: 1;
        border-radius: 30px;
    }
    .rounded-picture-border {
        position: absolute;
        top: 5%;
        right: 5px;
        transform: translateY(15%);
        height: 85%;
        width: 200px;
        border: 3px solid rgb(22, 162, 73);
        border-radius: 30px;
        z-index: 1;
    }
    .image-section {
        z-index: 2; /* Adjust the z-index of the image */
        top: 50%;
        left: -10%;
        margin-top: 10%;
    }
    .image-container {
        margin: auto;
        position: relative;
        width: 200px;
        padding-top: 30px;
        right: -15px;
      }
      .green-container {
        padding-top: 30px;
      }
      .container {
        position: relative;
        width: 112px;
        height: 92px;
        overflow: hidden;
        border-radius: 20px;
        box-shadow: 20px 10px 6px rgba(0, 0, 0, 0.5);
    }
  }
  /* Media query for SM - Small to medium tablet screens - 600px > < 960px */
  @media screen and (min-width: 600px) and (max-width: 960px) {
    h1 {
      font-size: 16px; /* Adjust font size for h1 */  }
    p {
        font-size: 10px; /* Adjust font size for p */
    }
    .rounded-picture-border {
      position: absolute;
      top: 0%;
      right: -25px;
      transform: translateY(15%);
      height: 92%;
      width: 200px;
      border: 3px solid rgb(22, 162, 73);
      border-radius: 10px;
      z-index: 1;
    }
    .text-container {
      margin: auto;
    }
    .rounded-rectangle {
      height: 124px;
      width: 25px;
      background-color: rgb(22, 162, 73);
      border-radius: 5px; /* Adjust the radius as needed */
    }
    .green-container {
      padding-top: 10px;
    }
    .image-container {
        margin: auto;
        position: relative;
        width: 200px;
        padding-top: 30px;
        right: -20px;
      }
      .container {
        position: relative;
        width: 102px;
        height: 92px;
        overflow: hidden;
        border-radius: 20px;
        box-shadow: 20px 10px 6px rgba(0, 0, 0, 0.5);
    }
    .p-container {
        padding-bottom: 5px;
      }
     
  }
  
  /* Media query for MD - Large tablet to laptop screens - 960px > < 1280px */
  @media screen and (min-width: 960px) and (max-width: 1280px) {
      h1 {
          font-size: 18px; /* Adjust the font size for smaller screens */
      }
      p {
          font-size: 12px; /* Adjust font size for p */
      }
      .image-container {
        position: relative;
        width: 450px;
        padding-top: 10px;
        justify-content: center; /* Center content horizontally */
        align-items: center; /* Center content vertically */
      }
      .rounded-picture-border {
        position: absolute;
        top: -5%;
        right: -20px;
        transform: translateY(15%);
        height: 95%;
        width: 250px;
        border: 3px solid rgb(22, 162, 73);
        border-radius: 10px;
        z-index: 1;
      }
      .green-container {
        padding-top: 10px;
      }
      .rounded-rectangle {
        height: 174px;
        width: 35px;
        background-color: rgb(22, 162, 73);
        border-radius: 5px; /* Adjust the radius as needed */
      }
      .image-container {
        margin: auto;
        position: relative;
        width: 250px;
        padding-top: 30px;
        right: -20px;
      }
      .container {
        position: relative;
        width: 132px;
        height: 112px;
        overflow: hidden;
        border-radius: 20px;
        box-shadow: 20px 10px 6px rgba(0, 0, 0, 0.5);
    }
  
  }
  /* Media query for LG - Laptop to desktop screens - 1280px > < 1920px */
  @media screen and (min-width: 1280px) and (max-width: 1919px) {
      h1 {
          font-size: 20px; /* Adjust the font size for smaller screens */
        }
      p {
          font-size: 12px; /* Adjust font size for p */
  
      }

      .rounded-picture-border {
        position: absolute;
        top: -5%;
        right: -25px;
        transform: translateY(15%);
        height: 95%;
        width: 300px;
        border: 3px solid rgb(22, 162, 73);
        border-radius: 10px;
        z-index: 1;
      }
      .green-container {
        padding-left: 10px;
      }
      .rounded-rectangle {
        height: 154px;
        width: 35px;
        background-color: rgb(22, 162, 73);
        border-radius: 5px; /* Adjust the radius as needed */
      }
      .image-container {
        margin: auto;
        position: relative;
        width: 300px;
        padding-top: 30px;
        right: -60px;
      }
      .container {
        position: relative;
        width: 162px;
        height: 102px;
        overflow: hidden;
        border-radius: 20px;
        box-shadow: 20px 10px 6px rgba(0, 0, 0, 0.5);
    }
  
  }
  /* Media query for XL - 1080p to 1440p desktop - 1920px > < 2560px */
  @media screen and (min-width: 1920px) and (max-width: 2560px) {
      h1 {
          font-size: 30px; /* Adjust font size for h1 */
          margin-bottom: 10px; /* Adjust margin bottom for h1 */
      }
      p {
        font-size: 20px; /* Adjust font size for p */
        padding-right: 50px;
      }
      .image-section {
        width: 350px;
        height: 510px;
      }
      .image-container {
        margin: auto;
        position: relative;
        padding-top: 30px;
      }
      .rounded-picture-border {
        position: absolute;
        top: -5%;
        right: -5px;
        transform: translateY(15%);
        width: 350px;
        height: 510px;
        border: 3px solid rgb(22, 162, 73);
        border-radius: 10px;
        z-index: 1;
      }
      .text-container {
        margin: auto;
      }
      .rounded-rectangle {
        height: 154px;
        width: 35px;
        background-color: rgb(22, 162, 73);
        border-radius: 5px; /* Adjust the radius as needed */
      }
      .green-container {
        padding-top: 30px;
      }
      
  
  }
  /* Media query for XXL - 4k and ultra-wide - > 2560px */
  @media screen and (min-width: 2560px) and (max-width: 5430px) {
    h1 {
        font-size: 18px; /* Adjust font size for h1 */
        margin-bottom: 10px; /* Adjust margin bottom for h1 */
    }
    p {
      font-size: 12px; /* Adjust font size for p */
      padding-right: 50px;
    }
    .image-container {
      margin: auto;
      position: relative;
      width: 450px;
      padding-top: 30px;
    }
    .rounded-picture-border {
      position: absolute;
      top: 10%;
      right: -25px;
      transform: translateY(15%);
      height: 85%;
      width: 450px;
      border: 3px solid rgb(22, 162, 73);
      border-radius: 10px;
      z-index: 1;
    }
    .text-container {
      margin: auto;
    }
    .rounded-rectangle {
      height: 254px;
      width: 35px;
      background-color: rgb(22, 162, 73);
      border-radius: 5px; /* Adjust the radius as needed */
    }
    .green-container {
        padding-top: 30px;
    }
      
  }
    
</style>