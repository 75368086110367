<template>
    <v-container>
            <v-row  justify="center" class="pb-16 pt-8" >
                <v-col cols="10" md="10" xl="8" >
                    <v-row >
                        <h1>CHARGE AHEAD WITH EASE:<br>NO-COST EV CHARGING SOLUTIONS FOR YOUR BUSINESS</h1>
                    </v-row>
                <v-row class="pt-2">
                    <p>Empower your business to lead the charge into a sustainable future effortlessly. With our 'Charge Ahead with Ease' program, enjoy the benefits of cutting-edge EV charging solutions tailored for your business, all at no upfront cost. Say goodbye to worries about installations, maintenance, and operations – we've got you covered on the journey towards a greener tomorrow.</p>
                </v-row>
            <v-row class="pt-3">
                <router-link to="/no-cost-model">
                <v-btn color="green" text="p-text">LEARN MORE</v-btn>
            </router-link>
            </v-row>
            </v-col>
        </v-row>


    </v-container>
</template>

<style scoped>
h1 {
    font-family: "Saira", sans-serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
    font-variation-settings:
      "wdth" 100;
    color: black;
}
p  {
  font-family: "Roboto Serif", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
  color: black;
}
  .p-sub-text {
    font-family: "Roboto Serif", serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
    font-size: 18px;
    font-variation-settings:
        "wdth" 100;
    color: black;
}
.green {
      color: rgb(22, 162, 73);
}
/* Media query for XS - Small to large phone screens - < 360px */
@media screen and (max-width: 360px) {
    h1 {
      font-size: 13px; /* Adjust font size for h1 */
    }
    p {
      font-size: 11px; /* Adjust font size for h1 */
    }
    .image-container {
      margin-left: 20px; /* Adjust left margin */
      margin-right: 20px; /* Adjust left margin */
      position: relative;
      height: 669px;
      width: 1636px;
    }
    .text-overlay {
      position: relative;
      top: 80%;
      left: 30%;
      transform: translate(-50%, -50%);
    }
    .v-btn {
      font-size: 11px; /* Adjust font size for h1 */
    }
  }
  /* Media query for XS - Small to large phone screens - 361px > < 599px */
  @media screen and (min-width: 361px) and (max-width: 599px) {
    h1 {
        font-size: 13px; /* Adjust font size for h1 */
    }
    p {
      font-size: 11px; /* Adjust font size for h1 */
    }
    .image-container {
      margin-left: 20px; /* Adjust left margin */
      margin-right: 20px; /* Adjust left margin */
      position: relative;
      height: 669px;
      width: 1636px;
    }
    .text-overlay {
      position: relative;
      top: 80%;
      left: 30%;
      transform: translate(-50%, -50%);
    }
    .heading {
        font-size: 18px;
    }
    .p-text {
        font-size: 15px;
    }
    .box-container {
        margin-right: 80px; /* Adjust left margin */
        margin-left: 80px; /* Adjust left margin */
    }
    
  }
  /* Media query for SM - Small to medium tablet screens - 600px > < 960px */
  @media screen and (min-width: 600px) and (max-width: 959px) {
    h1 {
      font-size: 18px; /* Adjust font size for h1 */
    }
    p {
      font-size: 12px; /* Adjust font size for h1 */
    }
    .image-container {
      margin-left: 20px; /* Adjust left margin */
      margin-right: 20px; /* Adjust left margin */
      position: relative;
      height: 669px;
      width: 1636px;
    }
    .text-overlay {
      position: relative;
      top: 80%;
      left: 30%;
      transform: translate(-50%, -50%);
    }
   
  }
  
  /* Media query for MD - Large tablet to laptop screens - 960px > < 1280px */
  @media screen and (min-width: 960px) and (max-width: 1279px) {
    h1 {
      font-size: 18px; /* Adjust font size for h1 */
    }
    p {
      font-size: 16px; /* Adjust font size for h1 */
    }
    .image-container {
      margin-left: 20px; /* Adjust left margin */
      margin-right: 20px; /* Adjust left margin */
      position: relative;
      height: 669px;
      width: 1636px;
    }
    .text-overlay {
      position: relative;
      top: 80%;
      left: 30%;
      transform: translate(-50%, -50%);
    }
  
  }
  /* Media query for LG - Laptop to desktop screens - 1280px > < 1920px */
  @media screen and (min-width: 1280px) and (max-width: 1919px) {
    h1 {
      font-size: 20px; /* Adjust font size for h1 */
    }
    p {
      font-size: 18px; /* Adjust font size for h1 */
      padding-bottom: 10px;
    }
    .image-container {
      margin-left: 20px; /* Adjust left margin */
      margin-right: 20px; /* Adjust left margin */
      position: relative;
      height: 669px;
      width: 1636px;
    }
    .text-overlay {
      position: absolute;
      top: 50%;
      left: 20%;
      transform: translate(-50%, -50%);
    }

  
  }
  /* Media query for XL - 1080p to 1440p desktop - 1920px > < 2560px */
  @media screen and (min-width: 1920px) and (max-width: 2560px) {
    h1 {
      font-size: 18px; /* Adjust font size for h1 */
    }
    p {
      font-size: 14px; /* Adjust font size for h1 */
      padding-bottom: 10px;
    }
    .text-overlay {
      position: absolute;
      top: 50%;
      left: 20%;
      transform: translate(-50%, -50%);
    }
  }
  /* Media query for XXL - 4k and ultra-wide - > 2560px */
  @media screen and (min-width: 2560px) and (max-width: 5430px) {
    h1 {
      font-size: 18px; /* Adjust font size for h1 */
    }
    p {
      font-size: 14px; /* Adjust font size for h1 */
    }
    .image-container {
      margin-left: 20px; /* Adjust left margin */
      margin-right: 20px; /* Adjust left margin */
      position: relative;
      height: 669px;
      width: 1636px;
    }
    .text-overlay {
      position: absolute;
      top: 50%;
      left: 20%;
      transform: translate(-50%, -50%);
    }
  
  }
</style>