<template> 
    <v-container class=" pt-5 pb-16">
        <v-row justify="center">
          <v-col cols="10" md="10" xs="6">
            <p class="">
              Our cutting-edge EV charging solutions and seamless smart grid integration redefine the charging experience. Join us in building tomorrow's infrastructure, expanding EV charging networks for reliable and accessible charging everywhere. At EV Networks, we're shaping the future of electric mobility.
            </p>
            <v-row class="pt-10" justify="center">
              <v-divider :length="803" :thickness="5" class="border-opacity-100 green"></v-divider>
            </v-row>
          </v-col>
        </v-row>
      </v-container>



</template>

<style scoped>
p {
    font-family: "Roboto Serif", serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
    font-variation-settings:
        "wdth" 100;
    color: black;
}
.green {
    color: rgb(22, 162, 73);
}
/* Media query for XS - Small to large phone screens - < 360px */
@media screen and (max-width: 360px) {
  p {
    font-size: 14px; /* Adjust font size for p */
}
  .image-container {
    margin: auto;
    position: relative;
    width: 250px;
    padding-top: 30px;
  }
  .rounded-picture-border {
    position: absolute;
    top: 10%;
    right: -25px;
    transform: translateY(15%);
    height: 85%;
    width: 250px;
    border: 3px solid rgb(22, 162, 73);
    border-radius: 10px;
    z-index: 1;
  }
  .text-container {
    margin: auto;
  }
  .rounded-rectangle {
    height: 254px;
    width: 25px;
    background-color: rgb(22, 162, 73);
    border-radius: 5px; /* Adjust the radius as needed */
  }   
  .box-container {
    padding-top: 100px;
  }
   
}
/* Media query for XS - Small to large phone screens - 361px > < 599px */
@media screen and (min-width: 361px) and (max-width: 599px) {
    p {
        font-size: 14px; /* Adjust font size for p */
    }
    .rounded-rectangle {
      height: 205px;
      width: 25px;
      background-color: rgb(22, 162, 73);
      border-radius: 5px; /* Adjust the radius as needed */
    }
    .container {
      position: relative;
      width: 100px;
      height: 90px;
      overflow: hidden;
      border-radius: 20px;
      box-shadow: 20px 10px 6px rgba(0, 0, 0, 0.5);
  }
  .image {
      width: 40%;
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      object-fit: cover;
      z-index: 1;
      border-radius: 30px;
  }
  .rounded-picture-border {
      position: absolute;
      top: -10%;
      right: -5px;
      transform: translateY(15%);
      height: 100%;
      width: 280px;
      border: 3px solid rgb(22, 162, 73);
      border-radius: 30px;
      z-index: 1;
  }
  .image-section {
      z-index: 2; /* Adjust the z-index of the image */
      top: 50%;
      left: -10%;
      margin-top: 10%;
  }
  .box-container {
      padding-right: 50%;
  }
}
/* Media query for SM - Small to medium tablet screens - 600px > < 960px */
@media screen and (min-width: 600px) and (max-width: 960px) {
  h1 {
    font-size: 16px; /* Adjust font size for h1 */  }
  p {
      font-size: 12px; /* Adjust font size for p */
      padding-bottom: 10px;
      padding-right: 30px;
  }
  .image-container {
    margin: auto;
    position: relative;
    width: 450px;
    padding-top: 30px;
  }
  .rounded-picture-border {
    position: absolute;
    top: 10%;
    right: -25px;
    transform: translateY(15%);
    height: 85%;
    width: 450px;
    border: 3px solid rgb(22, 162, 73);
    border-radius: 10px;
    z-index: 1;
  }
  .text-container {
    margin: auto;
  }
  .rounded-rectangle {
    height: 154px;
    width: 25px;
    background-color: rgb(22, 162, 73);
    border-radius: 5px; /* Adjust the radius as needed */
  }
  .box-container {
    padding-top: 100px;
  }
   
}

/* Media query for MD - Large tablet to laptop screens - 960px > < 1280px */
@media screen and (min-width: 960px) and (max-width: 1280px) {
    h1 {
        font-size: 18px; /* Adjust the font size for smaller screens */
    }
    p {
        font-size: 12px; /* Adjust font size for p */
    }
    .image-container {
      position: relative;
      width: 450px;
      padding-top: 10px;
      justify-content: center; /* Center content horizontally */
      align-items: center; /* Center content vertically */
    }
    .rounded-picture-border {
      position: absolute;
      top: -5%;
      right: -40px;
      transform: translateY(15%);
      height: 100%;
      width: 457px;
      border: 3px solid rgb(22, 162, 73);
      border-radius: 10px;
      z-index: 1;
    }
    .box-container {
      padding-top: 130px;
    }
    .rounded-rectangle {
      height: 174px;
      width: 35px;
      background-color: rgb(22, 162, 73);
      border-radius: 5px; /* Adjust the radius as needed */
    }
    

}
/* Media query for LG - Laptop to desktop screens - 1280px > < 1920px */
@media screen and (min-width: 1280px) and (max-width: 1920px) {
    h1 {
        font-size: 20px; /* Adjust the font size for smaller screens */
      }
    p {
        font-size: 18px; /* Adjust font size for p */

    }
    .image-container {
      position: relative;
      width: 450px;
    }
    .rounded-picture-border {
      position: absolute;
      top: -5%;
      right: -40px;
      transform: translateY(15%);
      height: 100%;
      width: 450px;
      border: 3px solid rgb(22, 162, 73);
      border-radius: 10px;
      z-index: 1;
    }
    .box-container {
      padding-left: 100px;
    }
    .rounded-rectangle {
      height: 254px;
      width: 35px;
      background-color: rgb(22, 162, 73);
      border-radius: 5px; /* Adjust the radius as needed */
    }

}
/* Media query for XL - 1080p to 1440p desktop - 1920px > < 2560px */
@media screen and (min-width: 1920px) and (max-width: 2560px) {
    p {
      font-size: 18px; /* Adjust font size for p */
    }
    .image-container {
      margin: auto;
      position: relative;
      width: 450px;
      padding-top: 30px;
    }
    .rounded-picture-border {
      position: absolute;
      top: 10%;
      right: -25px;
      transform: translateY(15%);
      height: 85%;
      width: 450px;
      border: 3px solid rgb(22, 162, 73);
      border-radius: 10px;
      z-index: 1;
    }
    .text-container {
      margin: auto;
    }
    .rounded-rectangle {
      height: 254px;
      width: 35px;
      background-color: rgb(22, 162, 73);
      border-radius: 5px; /* Adjust the radius as needed */
    }
    .box-container {
      padding-right: 100px;
    }
    

}
/* Media query for XXL - 4k and ultra-wide - > 2560px */
@media screen and (min-width: 2560px) and (max-width: 5430px) {
    h1 {
        font-size: 25px; /* Adjust font size for h1 */
        margin-bottom: 10px; /* Adjust margin bottom for h1 */
    }
    p {
      font-size: 20px; /* Adjust font size for p */
      padding-right: 50px;
    }
    .image-container {
      margin: auto;
      position: relative;
      width: 450px;
      padding-top: 30px;
    }
    .rounded-picture-border {
      position: absolute;
      top: 10%;
      right: -25px;
      transform: translateY(15%);
      height: 85%;
      width: 450px;
      border: 3px solid rgb(22, 162, 73);
      border-radius: 10px;
      z-index: 1;
    }
   
    .text-container {
      margin: auto;
    }
    .rounded-rectangle {
      height: 254px;
      width: 35px;
      background-color: rgb(22, 162, 73);
      border-radius: 5px; /* Adjust the radius as needed */
    }
    
}
</style>