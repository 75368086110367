<template>
    <v-container class="pt-16">
        <v-row justify="center">
            <v-col cols="12" sm="8" class="text-center">
                <v-row justify="center">
                    <h1><span class="green text-wrap">EV Networks</span>, your go-to source for <span class="green">hassle-free</span> EV charging installations. </h1>
                </v-row>
                <v-row justify="center" class="pa-3 pt-10 pb-5">
                    <v-divider length="550px" :thickness="5" class="border-opacity-100 green mx-auto"></v-divider>  
                </v-row>
            </v-col>
        </v-row>

        <v-row justify="center">
            <v-col class="box-container" cols="10" md="12" xl="10" >
                <v-row justify="center">
                <v-col cols="12" sm="6" lg="3">
                    <v-card 
                    class="text-center px-1 pt-16 green-border"
                    variant="outlined"
                    @mouseover="isHovered = true"
                    @mouseout="isHovered = false"
                    :class="{ 'green-border': isHovered }"
                    >
                        <v-img
                        src="@/assets/domain.png"
                        height="50px"
                        alt="Card image"
                        ></v-img>
                        <v-card-title class="pt-10 mx-1 heading text-wrap text-sm-truncate">Comprehensive Solutions</v-card-title>
                        <v-card-text class="mb-16 mx-1 p-text text-wrap text-sm-truncate">
                            Tailored services for residential and commercial needs.
                            </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                    <v-card 
                    class="text-center px-1 pt-16 green-border"
                    variant="outlined"
                    @mouseover="isHovered = true"
                    @mouseout="isHovered = false"
                    :class="{ 'green-border': isHovered }"
                    >
                        <v-img
                        src="@/assets/battery-charging-50.png"
                        height="50px"
                        alt="Card image"
                        
                        ></v-img>
                            <v-card-title class="pt-10 mx-1 heading text-wrap text-sm-truncate">Cutting-Edge Technology</v-card-title>
                            <v-card-text class="mb-16 mx-1 p-text text-wrap text-sm-truncate">
                                Smart charging stations for efficient and user-friendly experiences.
                            </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                    <v-card 
                    class="text-center px-1 pt-16 green-border"
                    variant="outlined"
                    @mouseover="isHovered = true"
                    @mouseout="isHovered = false"
                    :class="{ 'green-border': isHovered }"
                    >
                        <v-img
                        src="@/assets/car-connected.png"
                        height="50px"
                        alt="Card image"
                        class=""
                        
                        ></v-img>
                        <v-card-title class="pt-10 mx-1 heading text-wrap text-sm-truncate">Network Accessibility</v-card-title>
                        <v-card-text class="mb-16 mx-1 p-text text-wrap text-sm-truncate">
                            Our robust EV charging network is designed to simplify your travel experience.
                            </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                    <v-card 
                    class="text-center px-1 pt-16 green-border"
                    variant="outlined"
                    @mouseover="isHovered = true"
                    @mouseout="isHovered = false"
                    :class="{ 'green-border': isHovered }"
                    >
                        <v-img
                        src="@/assets/lightning-bolt.png"
                        height="50px"
                        alt="Card image"
                        class=""
                        
                        ></v-img>
                        <v-card-title class="pt-10 mx-1 heading text-wrap text-sm-truncate">Sustainability Focus</v-card-title>
                        <v-card-text class="mb-16 mx-1 p-text text-wrap text-sm-truncate">
                            Minimizing environmental impact with energy-efficient solutions.
                            </v-card-text>
                    </v-card>
                </v-col>
                
            </v-row>
            </v-col>
        </v-row>
</v-container>

</template>
<script>
export default {
  data() {
    return {
      isHovered: false
    };
  }
}
</script>

<style scoped>
h1 {
    font-family: "Saira", sans-serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
    font-variation-settings:
      "wdth" 100;
    color: black;
}
.heading {
    font-family: "Saira", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-size: 20px;
    font-variation-settings:
      "wdth" 100;
    color: black;
}
.p-text {
    font-family: "Roboto Serif", serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
    font-variation-settings:
        "wdth" 100;
    color: black;
}
.green {
    color: rgb(22, 162, 73);
}
.green-border {
    border: 2px solid rgba(0, 0, 0, 0);
    border-width: 2px; /* Adjust the thickness as needed */
  }
  
.green-border:hover {
    border-color: rgb(22, 162, 73);
    border-width: 2px;
}
/* Media query for XS - Small to large phone screens - < 360px */
@media screen and (max-width: 360px) {
    h1 {
      font-size: 16px; /* Adjust font size for h1 */
      text-align: center; /* Center the text horizontally */
    }
    .heading {
        font-size: 18px;
    }
    .p-text {
        font-size: 15px;
    }
    .box-container {
        margin-right: 100px; /* Adjust left margin */
        margin-left: 100px; /* Adjust left margin */
    }
 
  }
  /* Media query for XS - Small to large phone screens - 361px > < 599px */
  @media screen and (min-width: 361px) and (max-width: 599px) {
    h1 {
        font-size: 18px; /* Adjust font size for h1 */
        text-align: center; /* Center the text horizontally */
        margin-right: 40px; /* Adjust left margin */
        margin-left: 40px; /* Adjust left margin */

    }
    .heading {
        font-size: 18px;
    }
    .p-text {
        font-size: 15px;
    }
    .box-container {
        margin-right: 100px; /* Adjust left margin */
        margin-left: 100px; /* Adjust left margin */
    }
    
  }
  /* Media query for SM - Small to medium tablet screens - 600px > < 960px */
  @media screen and (min-width: 600px) and (max-width: 960px) {
    h1 {
      font-size: 18px; /* Adjust font size for h1 */
      margin-top: 5px; /* Adjust top margin */
    }
    .heading {
        font-size: 16px;
    }
    .p-text {
        font-size: 12px;
    }
    .box-container {
        margin-right: 100px; /* Adjust left margin */
        margin-left: 100px; /* Adjust left margin */
    }
    
  }
  
  /* Media query for MD - Large tablet to laptop screens - 960px > < 1280px */
  @media screen and (min-width: 960px) and (max-width: 1279px) {
    h1 {
      font-size: 16px; /* Adjust font size for h1 */
      margin-right: 150px; /* Adjust left margin */
      margin-left: 150px; /* Adjust left margin */
    }
    .heading {
        font-size: 18px;
    }
    .p-text {
        font-size: 15px;
    }
    .box-container {
        margin-right: 100px; /* Adjust left margin */
        margin-left: 100px; /* Adjust left margin */
    }
    
  
  }
  /* Media query for LG - Laptop to desktop screens - 1280px > < 1920px */
  @media screen and (min-width: 1280px) and (max-width: 1919px) {
    h1 {
      font-size: 20px; /* Adjust font size for h1 */
      margin-top: 5px; /* Adjust top margin */
      margin-left: 100px; /* Adjust left margin */
    }
    .heading {
        font-size: 18px;
    }
    .p-text {
        font-size: 15px;
    }
    .box-container {
        margin-right: 100px; /* Adjust left margin */
        margin-left: 100px; /* Adjust left margin */
    }
    

  
  }
  /* Media query for XL - 1080p to 1440p desktop - 1920px > < 2560px */
  @media screen and (min-width: 1920px) and (max-width: 2560px) {
    h1 {
      font-size: 25px; /* Adjust font size for h1 */
      margin-top: 5px; /* Adjust top margin */
      margin-left: 100px; /* Adjust left margin */
    }
    .heading {
        font-size: 16px;
    }
    .p-text {
        font-size: 12px;
    }
    .box-container {
        margin-right: 100px; /* Adjust left margin */
        margin-left: 100px; /* Adjust left margin */
    }
    
  }
  /* Media query for XXL - 4k and ultra-wide - > 2560px */
  @media screen and (min-width: 2560px) and (max-width: 5430px) {
    h1 {
      font-size: 25px; /* Adjust font size for h1 */
    }
    .heading {
        font-size: 16px;
    }
    .p-text {
        font-size: 12px;
    }
    .box-container {
        margin-right: 100px; /* Adjust left margin */
        margin-left: 100px; /* Adjust left margin */
    }
    
  }
</style>