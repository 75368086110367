<template>
  <v-app>
    <NavBar />
    <v-main class="main-content" :class="{ 'fade-in': showContent }">
      <Hero />
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <Banner />
      <SectionFour />
      <Footer />
    </v-main>
  </v-app>

</template>

<script>
import { defineComponent } from 'vue';

// Components
import NavBar from '../components/Main-NavBar.vue';
import Hero from '../components/Home-Page/Home-Hero.vue';
import SectionOne from '../components/Home-Page/Home-SectionOne.vue';
import SectionTwo from '../components/Home-Page/Home-SectionTwo.vue';
import SectionThree from '../components/Home-Page/Home-SectionThree.vue';
import Banner from '../components/Home-Page/Home-Banner.vue';
import SectionFour from '../components/Home-Page/Home-SectionFour.vue';
import Footer from '../components/Main-Footer.vue';

export default defineComponent({
  name: 'HomeView',

  components: {
    NavBar, Hero, SectionOne, SectionTwo, SectionThree, Banner, SectionFour, Footer,
  },
  data() {
    return {
      showContent: false,
    };
  },
  mounted() {
    // Set showContent to true after a short delay
    setTimeout(() => {
      this.showContent = true;
    }, 100);
    
  },
});
</script>

<style>
.main-content {
  max-width: 100%; /* Ensure content does not exceed viewport width */
  overflow-x: hidden; /* Hide horizontal overflow */
}
.fade-in {
  opacity: 100;
  transition: opacity 1.5s ease-in;
}

.fade-in.showContent {
  opacity: 1;

}
</style>


