<template>
  <v-container class="py-16">
    <v-row justify="start">
          <v-col class="flex-lg-row-reverse order-1 order-lg-2 box-container" cols="12" xs="12" sm="11" lg="6" xl="6" align-self="center">
            <v-row justify="center">
              <v-col cols="1" align-self="center" class="">
                <v-row justify="end">
                  <div class="rounded-rectangle"></div>
                </v-row>
              </v-col>
              <v-col  align-self="center" cols="10" xs="12" sm="11" md="11" lg="11">
                <h1 class="text-uppercase pb-2">ev charging is the future</h1>
                <v-divider
                  length="450px"
                  :thickness="5"
                  class="py-2 border-opacity-100 green"
                ></v-divider>
                <p>
                  Electric vehicle (EV) charging offers a cleaner, cost-effective 
                  alternative to traditional fueling, significantly reducing carbon 
                  emissions. With growing accessibility and technological advancements, 
                  EV charging is a convenient and efficient choice for a sustainable 
                  future, promoting environmental well-being and economic savings. 
                  Embracing EV charging is a pivotal step towards a greener transportation 
                  landscape.
                </p>
              </v-col>
            </v-row>
          </v-col>
          <v-row justify="center">
              <v-col class="flex-lg-row-reverse" cols="12" xs="12" offset-xl="2" xl="6" sm="10" md="6" >
            <div align="center" class="image-container">
              <v-img
                class="rounded-lg image"
                aspect-ratio="16/9"
                cover
                src="@/assets/section-three-image.jpeg"
              ></v-img>
              <div class="rounded-picture-border"></div>
            </div>

          </v-col>
        </v-row>

    </v-row>
  </v-container>
  
  
  </template>

<script>

</script>

<style scoped>
h1 {
  font-family: "Saira", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
  color: black;
}
p {
font-family: "Roboto Serif", serif;
font-optical-sizing: auto;
font-weight: 500;
font-style: normal;
font-variation-settings:
  "wdth" 100;
color: black;
}
.rounded-rectangle {
  height: 254px;
  width: 35px;
  background-color: rgb(22, 162, 73);
  border-radius: 5px; /* Adjust the radius as needed */
}

.image {
  z-index: 2; /* Adjust the z-index of the image */
}
.green {
  color: rgb(22, 162, 73);
}

/* Media query for XS - Small to large phone screens - < 360px */
@media screen and (max-width: 360px) {
  h1 {
    font-size: 15px; /* Adjust font size for h1 */
  }
  p {
      font-size: 10px; /* Adjust font size for p */
      padding-bottom: 10px;
  }
  .image-container {
    margin: auto;
    position: relative;
    width: 250px;
    padding-top: 30px;
  }
  .rounded-picture-border {
    position: absolute;
    top: 10%;
    right: -25px;
    transform: translateY(15%);
    height: 85%;
    width: 250px;
    border: 3px solid rgb(22, 162, 73);
    border-radius: 10px;
    z-index: 1;
  }
  .text-container {
    margin: auto;
  }
  .rounded-rectangle {
    height: 254px;
    width: 25px;
    background-color: rgb(22, 162, 73);
    border-radius: 5px; /* Adjust the radius as needed */
  }   
  .box-container {
    padding-top: 100px;
  }
   
}
/* Media query for XS - Small to large phone screens - 361px > < 599px */
@media screen and (min-width: 361px) and (max-width: 599px) {
    h1 {
        font-size: 16px; /* Adjust font size for h1 */
    }
    p {
        font-size: 12px; /* Adjust font size for p */
        padding-bottom: 10px;
    }
    .image-container {
      margin: auto;
      position: relative;
      width: 300px;
      padding-top: 30px;
    }
    .rounded-picture-border {
      position: absolute;
      top: 10%;
      right: -25px;
      transform: translateY(15%);
      height: 85%;
      width: 300px;
      border: 3px solid rgb(22, 162, 73);
      border-radius: 10px;
      z-index: 1;
    }
    .text-container {
      margin: auto;
    }
    .rounded-rectangle {
      height: 254px;
      width: 25px;
      background-color: rgb(22, 162, 73);
      border-radius: 5px; /* Adjust the radius as needed */
    }
    .box-container {
      padding-top: 100px;
    }
}
/* Media query for SM - Small to medium tablet screens - 600px > < 960px */
@media screen and (min-width: 600px) and (max-width: 960px) {
  h1 {
    font-size: 16px; /* Adjust font size for h1 */  }
  p {
      font-size: 12px; /* Adjust font size for p */
      padding-bottom: 10px;
      padding-right: 30px;
  }
  .image-container {
    margin: auto;
    position: relative;
    width: 450px;
    padding-top: 30px;
  }
  .rounded-picture-border {
    position: absolute;
    top: 10%;
    right: -25px;
    transform: translateY(15%);
    height: 85%;
    width: 450px;
    border: 3px solid rgb(22, 162, 73);
    border-radius: 10px;
    z-index: 1;
  }
  .text-container {
    margin: auto;
  }
  .rounded-rectangle {
    height: 154px;
    width: 25px;
    background-color: rgb(22, 162, 73);
    border-radius: 5px; /* Adjust the radius as needed */
  }
  .box-container {
    padding-top: 100px;
  }
   
}

/* Media query for MD - Large tablet to laptop screens - 960px > < 1280px */
@media screen and (min-width: 960px) and (max-width: 1280px) {
    h1 {
        font-size: 18px; /* Adjust the font size for smaller screens */
    }
    p {
        font-size: 12px; /* Adjust font size for p */
    }
    .image-container {
      position: relative;
      width: 450px;
      padding-top: 10px;
      justify-content: center; /* Center content horizontally */
      align-items: center; /* Center content vertically */
    }
    .rounded-picture-border {
      position: absolute;
      top: -5%;
      right: -40px;
      transform: translateY(15%);
      height: 100%;
      width: 457px;
      border: 3px solid rgb(22, 162, 73);
      border-radius: 10px;
      z-index: 1;
    }
    .box-container {
      padding-top: 130px;
    }
    .rounded-rectangle {
      height: 174px;
      width: 35px;
      background-color: rgb(22, 162, 73);
      border-radius: 5px; /* Adjust the radius as needed */
    }

}
/* Media query for LG - Laptop to desktop screens - 1280px > < 1920px */
@media screen and (min-width: 1280px) and (max-width: 1920px) {
    h1 {
        font-size: 20px; /* Adjust the font size for smaller screens */
      }
    p {
        font-size: 12px; /* Adjust font size for p */

    }
    .image-container {
      position: relative;
      width: 450px;
    }
    .rounded-picture-border {
      position: absolute;
      top: -5%;
      right: -40px;
      transform: translateY(15%);
      height: 100%;
      width: 450px;
      border: 3px solid rgb(22, 162, 73);
      border-radius: 10px;
      z-index: 1;
    }
    .box-container {
      padding-left: 100px;
    }
    .rounded-rectangle {
      height: 254px;
      width: 35px;
      background-color: rgb(22, 162, 73);
      border-radius: 5px; /* Adjust the radius as needed */
    }

}
/* Media query for XL - 1080p to 1440p desktop - 1920px > < 2560px */
@media screen and (min-width: 1920px) and (max-width: 2560px) {
    h1 {
        font-size: 18px; /* Adjust font size for h1 */
        margin-bottom: 10px; /* Adjust margin bottom for h1 */
    }
    p {
      font-size: 12px; /* Adjust font size for p */
      padding-right: 50px;
    }
    .image-container {
      margin: auto;
      position: relative;
      width: 450px;
      padding-top: 30px;
    }
    .rounded-picture-border {
      position: absolute;
      top: 10%;
      right: -25px;
      transform: translateY(15%);
      height: 85%;
      width: 450px;
      border: 3px solid rgb(22, 162, 73);
      border-radius: 10px;
      z-index: 1;
    }
    .text-container {
      margin: auto;
    }
    .rounded-rectangle {
      height: 254px;
      width: 35px;
      background-color: rgb(22, 162, 73);
      border-radius: 5px; /* Adjust the radius as needed */
    }
    .box-container {
      padding-right: 100px;
    }
    

}
/* Media query for XXL - 4k and ultra-wide - > 2560px */
@media screen and (min-width: 2560px) and (max-width: 5430px) {
    h1 {
        font-size: 25px; /* Adjust font size for h1 */
        margin-bottom: 10px; /* Adjust margin bottom for h1 */
    }
    p {
      font-size: 12px; /* Adjust font size for p */
      padding-right: 50px;
    }
    .image-container {
      margin: auto;
      position: relative;
      width: 450px;
      padding-top: 30px;
    }
    .rounded-picture-border {
      position: absolute;
      top: 10%;
      right: -25px;
      transform: translateY(15%);
      height: 85%;
      width: 450px;
      border: 3px solid rgb(22, 162, 73);
      border-radius: 10px;
      z-index: 1;
    }
   
    .text-container {
      margin: auto;
    }
    .rounded-rectangle {
      height: 254px;
      width: 35px;
      background-color: rgb(22, 162, 73);
      border-radius: 5px; /* Adjust the radius as needed */
    }
    
}

</style>