<template>
    <v-container class="">
  <v-row justify="center">
    <v-col cols="12" xl="10" lg="10" sm="10" class="d-flex flex-lg-row-reverse">
      <v-row>
        <!-- Order 1 on smaller screens -->
        <v-col cols="12" xl="6" lg="6"  align-self="center" class="d-flex flex-lg-row-reverse order-1 order-lg-2 box-container">
          <v-row align="center">
            <v-divider vertical length="700px" :thickness="5" class="pl-5 border-opacity-100 green"></v-divider>
            <v-col cols="11" md="11">
              <v-card flat class="pl-5 py-8">
                <h1>BENEFITS OF EV CHARGING</h1>
                <p>Get ready to experience the future of travel with EV Networks Charging Station - Your Gateway to Next-Gen EV Charging. Brace yourself for a paradigm shift in convenience, sustainability, and technological advancement.</p>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <!-- Order 2 on smaller screens -->
        <v-col cols="12" xl="6" lg="6" align-self="center" class="order-2 order-lg-1">
          <v-row>
            <v-col cols="12" class="d-flex flex-lg-row-reverse">
              <v-row>
                <v-col cols="12" lg="2" align-self="center" class="order-lg-2 text-center">
                  <v-card flat color="white" class="">
                    <v-avatar class="ma-3" size="45" rounded="0">
                      <v-img src="@/assets/asset-1.png"></v-img>
                    </v-avatar>
                  </v-card>
                </v-col>
                <v-col cols="12" lg="10" offset-xs="1" class="order-sm-1 text-sm-right text-center">
                  <v-card-text class="p-text">As the energy grid shifts towards renewable sources, EV charging becomes even more sustainable.<br /></v-card-text>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="d-flex flex-lg-row-reverse">
              <v-row>
                <v-col cols="12" lg="2" align-self="center" class="order-lg-2 text-center">
                  <v-card flat color="white" class="">
                    <v-avatar class="ma-3" size="50" rounded="0">
                      <v-img src="@/assets/asset-2.png"></v-img>
                    </v-avatar>
                  </v-card>
                </v-col>
                <v-col cols="12" lg="10" offset-xs="1" class="order-sm-1 text-sm-right text-center">
                  <v-card-text class="p-text">The transition to electric vehicles reduces dependency on finite oil resources, promoting energy security and mitigating geopolitical and economic risks associated with oil dependence.</v-card-text>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="d-flex flex-lg-row-reverse">
              <v-row>
                <v-col cols="12" lg="2" align-self="center" class="order-lg-2 text-center">
                  <v-card flat color="white" class="">
                    <v-avatar class="ma-3" size="50" rounded="0">
                      <v-img src="@/assets/asset-3.png"></v-img>
                    </v-avatar>
                  </v-card>
                </v-col>
                <v-col cols="12" lg="10" offset-xs="1" class="order-sm-1 text-sm-right text-center">
                  <v-card-text class="p-text">The adoption of electric vehicles and the installation of home charging stations, making it financially attractive for consumers to switch to EVs.</v-card-text>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</v-container>

    
      


</template>

<style scoped>

h1 {
    font-family: "Saira", sans-serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
    font-variation-settings:
      "wdth" 100;
    color: black;
}
p {
  font-family: "Roboto Serif", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-variation-settings:
      "wdth" 100;
  color: black;
}

.green {
    color: rgb(22, 162, 73);
}
/* Media query for XS - Small to large phone screens - < 360px */
@media screen and (max-width: 360px) {
  h1 {
      font-size: 20px; /* Adjust font size for h1 */
  }
  p {
    font-size: 12px;
    padding-top: 10px;
  }
  .p-text {
    text-align: center;
    font-size: 12px;
  }
}
/* Media query for XS - Small to large phone screens - 361px > < 599px */
@media screen and (min-width: 361px) and (max-width: 599px) {
  h1 {
      font-size: 16px; /* Adjust font size for h1 */
  }
  p {
    font-size: 12px;
    padding-top: 10px;
  }
  .p-text {
    text-align: center;
    font-size: 12px;
  }
}
/* Media query for SM - Small to medium tablet screens - 600px > < 960px */
@media screen and (min-width: 600px) and (max-width: 959px) {
  h1 {
      font-size: 18px; /* Adjust font size for h1 */
  }
  p {
    font-size: 12px;
    padding-top: 10px;
  }
  .p-text {
    text-align: center;
    font-size: 12px;
  }
  .box-container {
    display: flex; /* Use flexbox layout */
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
  }
}

/* Media query for MD - Large tablet to laptop screens - 960px > < 1280px */
@media screen and (min-width: 960px) and (max-width: 1279px) {
  h1 {
      font-size: 20px; /* Adjust font size for h1 */
  }
  p {
    font-size: 14px;
    padding-top: 10px;
  }
  .p-text {
    text-align: center;
    font-size: 12px;
  }

}
/* Media query for LG - Laptop to desktop screens - 1280px > < 1920px */
@media screen and (min-width: 1280px) and (max-width: 1919px) {
  h1 {
      font-size: 24px; /* Adjust font size for h1 */
  }
  p {
    font-size: 14px;
    padding-top: 10px;
  }
  .p-text {
    font-size: 14px;
  }
}
/* Media query for XL - 1080p to 1440p desktop - 1920px > < 2560px */
@media screen and (min-width: 1920px) and (max-width: 2559px) {
  h1 {
      font-size: 18px; /* Adjust font size for h1 */
  }
  p {
    font-size: 12px;
    padding-top: 10px;
  }
  .p-text {
    font-size: 12px;
  }
}
/* Media query for XXL - 4k and ultra-wide - > 2560px */
@media screen and (min-width: 2560px) and (max-width: 5430px) {
  h1 {
      font-size: 18px; /* Adjust font size for h1 */
  }
  p {
    font-size: 12px;
    padding-top: 10px;
  }
  .p-text {
    font-size: 12px;
  }
}
</style>